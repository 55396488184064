import React, { useState } from 'react';
import EditImageModalCard from '../Cards/EditImageModalCard';
import { TreeNode } from 'lib/tree';
import { Button } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { useUser } from 'reducers';
import './TreeRowElement.scss';

interface TreeRowElementProps {
    node: TreeNode;
}

const TreeRowElement = ({ node }: TreeRowElementProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const user = useUser();

    return (
        <span>
            {user?.permissions.canManageAssortmentImages && <EditImageModalCard path={'symKar' in node ? node.symKar : node.path} setModalVisibility={(visibility: boolean) => { setIsModalOpen(visibility); }} isModalOpen={isModalOpen} name={node.description as string} />}
            {node.description}
            {
                user?.permissions.canManageAssortmentImages && (
                    <span>
                        <Button
                            className='TreeRowElement-Edit-Button'
                            variant='outline'
                            title='Edytuj'
                            size='sm'
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsModalOpen(true);
                            }}
                            onMouseDown={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <FaEdit />
                        </Button>
                    </span>
                )
            }

        </span>
    );
};

export default TreeRowElement;
