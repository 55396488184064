import ButtonComponent from 'components/ButtonComponent';
import { runTests } from 'lib/communication/webserviceTests';
import { TestCaseResult } from 'lib/types';
import { LG } from 'lib/util';
import _ from 'lodash';
import React, { useState } from 'react';
import { OverlayTrigger, Popover, Table } from 'react-bootstrap';
import { FaMinus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { AppState } from 'reducers';

interface SpannedProceduresProps {
    procedures: string[];
}

export default function AutomaticTestsResults() {
    const testResults = useSelector((state: AppState) => state.data.webserviceTestResults.results);
    const isDesktop = useMediaQuery({ minWidth: LG });
    const spannedProcedures: SpannedProceduresProps = { procedures: [] };

    const printPopover = (errorLogs: string | undefined) => (
        <Popover id='popover-basic'>
            <Popover.Title>Zapisany błąd</Popover.Title>
            <Popover.Content>{errorLogs}</Popover.Content>
        </Popover>
    );

    const printResult = (currentTestCase: TestCaseResult | undefined) => {
        if (currentTestCase?.status === '0') {
            return <span className='pass-icon'>✔</span>;
        }
        if (currentTestCase === undefined) {
            return <FaMinus style={{ color: '#afafaf' }} />;
        }
        return (
            <OverlayTrigger
                trigger='click'
                placement='top'
                overlay={printPopover(currentTestCase?.exceptionMessage)}
                transition
            >
                <span className='error-icon'>✘</span>
            </OverlayTrigger>
        );
    };

    const getLastTestResult = () => {
        if (!testResults[0]) return <span>brak</span>;
        const failedTestCases = _.filter(testResults[0]?.testCasesResults, ({ status }) => status !== '0');
        return _.size(failedTestCases) === 0 ? (
            <span style={{ color: 'green' }}>pomyślny</span>
        ) : (
            <span style={{ color: 'red' }}>wystąpiły błędy</span>
        );
    };

    const [isTestRunMessageVisible, setIsTestRunMessageVisible] = useState<boolean>(false);

    return (
        <>
            <br />
            <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                <div style={{ flex: 1 }} />
                <div className='column' style={{ textAlign: 'center', flex: 1 }}>
                    <h2 className={isDesktop ? 'desktop-font-size' : 'mobile-font-size'}>
                        Data wykonania ostatnich testów:
                        <span style={{ fontWeight: 'bolder', display: 'block' }}>
                            {testResults[0] ? testResults[0].date : 'brak'}
                        </span>
                    </h2>
                    <h2 className={isDesktop ? 'desktop-font-size' : 'mobile-font-size'}>
                        Wynik ostatnich testów:
                        <span style={{ fontWeight: 'bolder' }}>{getLastTestResult()}</span>
                    </h2>
                    {isTestRunMessageVisible && (
                        <h2 className={isDesktop ? 'desktop-font-size' : 'mobile-font-size'}>
                            Pomyślnie uruchomiono testy
                        </h2>
                    )}
                    <hr className={isDesktop ? 'desktop-hr' : ''} />
                </div>
                <div style={{ textAlign: 'right', flexShrink: 0, flex: 1 }}>
                    <ButtonComponent
                        onClick={() => {
                            runTests('runtests');
                            setIsTestRunMessageVisible(true);
                        }}
                        text='Uruchom testy'
                        margin='0.25rem'
                        onHoverAnimation={3}
                    />
                </div>
            </div>
            <div className='TestsResults-container'>
                <div className='TestsResults-body'>
                    <Table bordered striped className={isDesktop ? '' : 'mobile-font-size'}>
                        <thead>
                            <tr>
                                {isDesktop && <th>LP.</th>}
                                <th>Procedura</th>
                                <th>Przypadek testowy</th>
                                {isDesktop ?
                                    testResults.map((testResult, idx) => (
                                        <th key={idx} className='Table-test-iteration'>
                                            {testResult.date.slice(0, 10)}
                                            <br />
                                            {testResult.date.slice(10)}
                                        </th>
                                    )) :
                                    testResults.slice(0, 3).map((testResult, idx) => (
                                        <th key={idx} className='Table-test-iteration'>
                                            {testResult.date.slice(0, 10)}
                                            <br />
                                            {testResult.date.slice(10)}
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {testResults[0]?.testCasesResults.map(({ prettifiedClassName, prettifiedMethodName }, index) => (
                                <tr
                                    key={index}
                                    style={
                                        spannedProcedures.procedures.includes(prettifiedClassName) ?
                                            {} :
                                            { borderTop: '2px solid grey' }
                                    }
                                >
                                    {isDesktop && <td className={'Table-cell'}>{index + 1}</td>}
                                    {!spannedProcedures.procedures.includes(prettifiedClassName) && (
                                        <td
                                            className='Table-test-class-name Table-cell'
                                            style={
                                                isDesktop ?
                                                    { overflowWrap: 'break-word' } :
                                                    { overflowWrap: 'anywhere' }
                                            }
                                            rowSpan={_.size(
                                                _.filter(
                                                    testResults[0]?.testCasesResults,
                                                    ({ prettifiedClassName: filterPrettifiedClassName }) => filterPrettifiedClassName === prettifiedClassName &&
                                                        spannedProcedures.procedures.push(prettifiedClassName)

                                                )
                                            )}
                                        >
                                            {prettifiedClassName}
                                        </td>
                                    )}
                                    <td className={'Table-cell'}>{prettifiedMethodName}</td>
                                    {isDesktop ?
                                        testResults.map(({ testCasesResults }, idx) => (
                                            <td key={idx} className={'Table-cell'}>
                                                {printResult(
                                                    _.find(
                                                        testCasesResults,
                                                        ({
                                                            prettifiedClassName: findPrettifiedClassName,
                                                            prettifiedMethodName: findPrettifiedMethodName
                                                        }) => findPrettifiedMethodName === prettifiedMethodName &&
                                                            findPrettifiedClassName === prettifiedClassName
                                                    )
                                                )}
                                            </td>
                                        )) :
                                        testResults.slice(0, 3).map(({ testCasesResults }, idx) => (
                                            <td key={idx} className={'Table-cell'}>
                                                {printResult(
                                                    _.find(
                                                        testCasesResults,
                                                        ({
                                                            prettifiedClassName: findPrettifiedClassName,
                                                            prettifiedMethodName: findPrettifiedMethodName
                                                        }) => findPrettifiedMethodName === prettifiedMethodName && findPrettifiedClassName === prettifiedClassName
                                                    )
                                                )}
                                            </td>
                                        ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    );
}
