/* eslint-disable import/prefer-default-export */
import { API_ADDRESS, post } from 'lib/communication';
import { ErrorInfo } from 'react';
import { userService } from 'services';

export const logClientError = async (err: Error | null, errInfo: ErrorInfo | null): Promise<void> => {
    const route = 'errors/logClientError';
    const { login } = userService.getUser();
    const error = err ? {
        name: err.name,
        message: err.message
    } : null;
    try {
        await post<Response>(`${API_ADDRESS}${route}`, {
            login,
            error,
            info: errInfo
        });
    } catch (e) {
        console.log('Error ', e);
    }
};
