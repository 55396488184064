import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './LinkItem.scss';
import { Role } from 'lib';
import { useUser } from 'reducers';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';

interface LinkItemProps {
    to: string;
    children: React.ReactNode;
    eventKey?: string | undefined;
    roles?: [Role];
    isActive?: boolean;
    textOnLeft?: boolean;
}

const LinkItem = ({ to, children, eventKey = undefined, roles = undefined, isActive, textOnLeft = false }: LinkItemProps) => {
    const user = useUser();
    const isDesktop = useMediaQuery({ minWidth: LG });

    if (roles !== undefined && !_.includes(roles, user?.role)) {
        return null;
    }

    return (
        <>
            <Nav.Item className='LinktItem'>
                <Link to={to} className={`${(isActive && isDesktop) ? 'LinkItem-anchor-active' : ''} ${textOnLeft && 'textOnLeft'} LinkItem-anchor`}>
                    <Nav.Link as='div' eventKey={!eventKey ? to : eventKey}>
                        {children}
                    </Nav.Link>
                </Link>
            </Nav.Item>
        </>
    );
};

export default LinkItem;
