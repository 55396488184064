import { API_ADDRESS, get, post, put } from 'lib/communication';
import { AdditionalTab } from 'lib/types';
import { dataActions } from 'reducers';
import store from 'store';
import { Response } from './communicationTypes';

const setIsAdditionalTabFetching = (isFetching: boolean) => {
    store.dispatch(dataActions.setIsAdditionalTabFetching(isFetching));
};

const setIsAdditionalTabBeingUpdated = (isBeingUpdated: boolean) => {
    store.dispatch(dataActions.setIsAdditionalTabBeingUpdated(isBeingUpdated));
};

export const getAdditionalTabDetails = async (): Promise<boolean> => {
    const route: string = 'additionalTab/getAdditionalTabDetails';

    try {
        setIsAdditionalTabFetching(true);
        const response = await get<AdditionalTab>(`${API_ADDRESS}${route}`);
        if (response.ok && response.parsedBody) {
            store.dispatch(dataActions.setAdditionalTabDetails(response.parsedBody));
            return true;
        }
        return false;
    } catch (error) {
        console.error(error);
        return false;
    } finally {
        setIsAdditionalTabFetching(false);
    }
};

export const updateAdditionalTabDetails = async (updatedAdditionalTabDetails: AdditionalTab): Promise<boolean> => {
    const route: string = 'additionalTab/updateAdditionalTabDetails';

    try {
        setIsAdditionalTabBeingUpdated(true);
        const response = await put<Response>(`${API_ADDRESS}${route}`, updatedAdditionalTabDetails);
        if (response.ok) {
            store.dispatch(dataActions.setAdditionalTabDetails(updatedAdditionalTabDetails));
            return true;
        }
        return false;
    } catch (error) {
        console.error(error);
        return false;
    } finally {
        setIsAdditionalTabBeingUpdated(false);
    }
};

export const getAdditionalTabName = async (): Promise<boolean> => {
    const route: string = 'additionalTab/getAdditionalTabName';

    try {
        setIsAdditionalTabFetching(true);
        const response = await get<AdditionalTab>(`${API_ADDRESS}${route}`);
        if (response.ok && response.parsedBody) {
            store.dispatch(dataActions.setAdditionalTabDetails(response.parsedBody));
            return true;
        }
        return false;
    } catch (error) {
        console.error(error);
        return false;
    } finally {
        setIsAdditionalTabBeingUpdated(false);
    }
};

export const uploadFile = async (file: File): Promise<string> => {
    const route: string = `news/uploadFile`;
    const formData = new FormData();
    formData.append('file', file);
    const args: RequestInit = { method: 'post', body: formData };
    const data = await post<Response>(`${API_ADDRESS}${route}`, formData, args);
    return data.parsedBody!.response;
};
