import './DeliveryAddressSelect.scss';
import React, { useState, useEffect } from 'react';
import Select, { components, OptionProps, MenuListComponentProps, StylesConfig, createFilter } from 'react-select';
import { FaEdit, FaTrashAlt, FaPlusCircle } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import ButtonComponent from 'components/ButtonComponent';
import DeliveryAddressForm from './DeliveryAddressForm';
import { getUserDeliveryAddresses, addUserDeliveryAddress, editUserDeliveryAddress, deleteUserDeliveryAddress } from 'lib/communication/userInfo';
import { UserDeliveryAddress } from 'lib/types';

interface DeliveryAddressSelectProps {
    required?: boolean;
    name?: string;
    addresses: UserDeliveryAddress[];
    deliveryAddress?: UserDeliveryAddress | undefined; // exposes address state, when not using form submit
    setDeliveryAddress?: (address: UserDeliveryAddress | undefined) => void; // exposes address state, when not using form submit
}

type OptionType = {
    label: string,
    value: string,
    data: UserDeliveryAddress
};

const DeliveryAddressSelect = (props: DeliveryAddressSelectProps) => {
    const [userDeliveryAddressList, setUserDeliveryAddressList] = useState<OptionType[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<UserDeliveryAddress | undefined>(props.deliveryAddress ?? undefined);
    const [editAddress, setEditAddress] = useState<UserDeliveryAddress | undefined>(undefined);
    const [deleteAddress, setDeleteAddress] = useState<UserDeliveryAddress | undefined>(undefined);
    const [showFormModal, setShowFormModal] = useState<boolean>(false);

    useEffect(() => {
        let userAddresses: OptionType[] = [];
        if (props.addresses.length) {
            userAddresses = props.addresses.map((addressData) => {
                const address = addressData.toAddressString();
                return { label: address, value: addressData.id, data: addressData };
            }) as OptionType[];
        }
        setUserDeliveryAddressList(userAddresses);
    }, [props.addresses]);

    const setAddress = (address: UserDeliveryAddress | undefined) => {
        setSelectedAddress(address);
        if (props.setDeliveryAddress) {
            props.setDeliveryAddress(address);
        }
    };

    const onFormModalCancel = () => {
        setShowFormModal(false);
        setEditAddress(undefined);
    };

    const onFormModalAccept = (formDeliveryAddressData: UserDeliveryAddress) => {
        if (formDeliveryAddressData.id) {
            editUserDeliveryAddress(formDeliveryAddressData).then((id) => {
                getUserDeliveryAddresses();
                // address should be selected after edit
                formDeliveryAddressData.id = id;
                setAddress(formDeliveryAddressData);
            });
        } else {
            addUserDeliveryAddress(formDeliveryAddressData).then((id) => {
                getUserDeliveryAddresses();
                // address should be selected after adding it
                formDeliveryAddressData.id = id;
                setAddress(formDeliveryAddressData);
            });
        }
        onFormModalCancel();
    };

    const onDeleteModalCancel = () => {
        setDeleteAddress(undefined);
    };

    const onDeleteModalAccept = () => {
        if (deleteAddress) {
            deleteUserDeliveryAddress(deleteAddress).then(() => getUserDeliveryAddresses());
        }
        onDeleteModalCancel();
    };

    const CustomStyle: StylesConfig = {
        control: (provided: any, state: any) => ({
            ...provided,
            borderColor: 'dark',
            borderRadius: '0px',
            boxShadow: state.isFocused ? '0 0 0 0.2rem var(--brand-bright)' : 'none'
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: 10 + 5 * 40, // padding + n * option height
            borderRadius: '0px'
        }),
        option: (provided, { data, isSelected }) => ({
            ...provided,
            backgroundColor: isSelected ? provided.backgroundColor : ((data.data.readOnly && !data.data.special) ? 'var(--brand)' : 'white'),
            borderBottom: 'solid 1px lightgray',
            '&:hover': {
                backgroundColor: isSelected ? provided.backgroundColor : '#deebff'
            },
            color: isSelected ? 'white' : 'black',
            borderRadius: '0px'
        }),
        menu: (provided: any) => ({
            ...provided,
            borderRadius: '0px'
        })
    };

    const CustomMenuList = (menuprops: MenuListComponentProps<OptionType>) => (
        <components.MenuList {...menuprops}>
            {menuprops.children}
            <div className='address-add-new' onClick={() => setShowFormModal(true)}>
                <FaPlusCircle />
                <span>Dodaj nowy adres dostawy</span>
            </div>
        </components.MenuList>
    );

    const CustomOption = (optionprops: OptionProps<OptionType>) => {
        const option: OptionType = optionprops.data;
        if (!option.data.readOnly) {
            return (
                <components.Option {...optionprops}>
                    <div className='address-option'>
                        <span className='address-option-label'>{option.label} <span className='address-option-label-desc'>{option.data.description}</span></span>
                        <div className='address-option-controls'>
                            <FaEdit
                                className='address-option-control edit'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setEditAddress(option.data);
                                    setShowFormModal(true);
                                }}
                            />
                            <FaTrashAlt
                                className='address-option-control trash'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteAddress(option.data);
                                }}
                            />
                        </div>
                    </div>
                </components.Option>
            );
        }
        return (
            <components.Option {...optionprops}>
                <div className='address-option'>
                    <span className='address-option-label'>{option.label} <span className='address-option-label-desc'>{option.data.description}</span></span>
                </div>
            </components.Option>
        );
    };

    const CustomFilter = createFilter({
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        matchFrom: 'any',
        stringify: (option) => `${option.data.label} ${option.data.data.description}`
    });

    return (
        <>
            <Modal show={!!deleteAddress} onHide={onDeleteModalCancel} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Czy na pewno chcesz usunąć adres?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {deleteAddress?.toAddressString()}
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        variant='success'
                        text='Anuluj'
                        onClick={onDeleteModalCancel}
                        onHoverAnimation={3}
                    />
                    <ButtonComponent
                        variant='danger'
                        text='Usuń'
                        onClick={onDeleteModalAccept}
                        brandColoredButton={false}
                    />
                </Modal.Footer>
            </Modal>
            <DeliveryAddressForm show={showFormModal} editData={editAddress} onCancel={onFormModalCancel} onAccept={onFormModalAccept} />
            <Select
                isClearable
                name={props.name ?? 'DeliveryAddress'} // form submit name
                required={props.required ?? false}
                placeholder='Wybierz adres dostawy inny niż domyślny'
                noOptionsMessage={() => 'Nie znaleziono adresu'}
                loadingMessage={() => 'Wczytywanie...'}
                isLoading={props.addresses === null}
                options={userDeliveryAddressList}
                onChange={(option) => {
                    const addressData = (option as OptionType)?.data;
                    if (addressData) {
                        setAddress(addressData ?? undefined);
                    } else {
                        setAddress(undefined);
                    }
                }}
                value={selectedAddress ? userDeliveryAddressList.find((val) => val.value === selectedAddress.id) : null}
                filterOption={CustomFilter}
                components={{ MenuList: CustomMenuList, Option: CustomOption }}
                styles={CustomStyle}
            />
        </>
    );
};

export default DeliveryAddressSelect;
