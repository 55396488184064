import { logClientError } from 'lib/communication/errors';
import { Component, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean,
    error: Error | null;
    errorInfo: ErrorInfo | null;
}

// eslint-disable-next-line react/prefer-stateless-function
class ErrorWrapper extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    async componentDidCatch(error: Error, errorInfo: ErrorInfo): Promise<void> {
        await logClientError(error, errorInfo);
    }

    render(): ReactNode {
        return this.props.children;
    }
}

export default ErrorWrapper;
