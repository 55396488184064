import React from 'react';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { AdminRole } from 'lib/communication/admin';
import { PermissionFlagName, PermissionFlagNames } from 'lib/types';
import './AdminRoleTable.scss';

interface AdminRoleTableProps {
    roles: AdminRole[]
}

const AdminRoleTable = (props: AdminRoleTableProps) => {
    const permissionDescriptions: Record<PermissionFlagName, JSX.Element> = {
        canDeleteNews: <>Usuwanie postów w zakładce <span className='AdminRoleTable-path'>AKTUALNOŚCI</span></>,
        canManageNews: <>Dodawanie, edycja i archiwizowanie postów w zakładce <span className='AdminRoleTable-path'>AKTUALNOŚCI</span></>,
        canManageAdmins: <>Dostęp do zakładki <span className='AdminRoleTable-path'>ADMIN &gt; ADMINISTRATORZY</span></>,
        canManageServiceWorks: <>Dostęp do aplikacji podczas prac serwisowych oraz dostęp do zakładki <span className='AdminRoleTable-path'>ADMIN &gt; PRACE SERWISOWE</span></>,
        canViewTests: <>Dostęp do zakładki <span className='AdminRoleTable-path'>ADMIN &gt; WYNIKI TESTÓW</span></>,
        canManageAssortmentImages: <>Wyświetlanie nazw plików obrazów asortymentu, możliwość ich edycji oraz dostęp do zakładki <span className='AdminRoleTable-path'>ADMIN &gt; GRAFIKI ASORTYMENTU</span></>,
        canManageCache: <>Dostęp do zakładki <span className='AdminRoleTable-path'>ADMIN &gt; CACHE</span></>,
        canViewStatistics: <>Dostęp do zakładki <span className='AdminRoleTable-path'>ADMIN &gt; STATYSTYKI</span></>,
        canEditAdditionalTab: <>Zarządzanie dodatkową zakładką</>,
        canEditTerms: <>Możliwość edycji polityki cookies oraz regulaminu promocji</>
    };

    const formatPermissionName = (permission: string) => permission.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');

    return (
        <>
            <Table borderless striped>
                <thead>
                    <tr className='AdminRoleTable-headerRow'>
                        <td>Nazwa roli</td>
                        {PermissionFlagNames.map((p) => (
                            <td key={p}>
                                <OverlayTrigger
                                    overlay={<Tooltip id='description'>{permissionDescriptions[p]}</Tooltip>}
                                >
                                    <span className='AdminRoleTable-roleHeader'>
                                        {formatPermissionName(p)}
                                    </span>
                                </OverlayTrigger>
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.roles.map((role) => (
                        <tr key={role.name}>
                            <td>{role.name}</td>
                            {PermissionFlagNames.map((p) => (
                                <td key={p}>
                                    {role[p] ? <FaCheck /> : <FaTimes style={{ color: 'red' }} />}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};

export default AdminRoleTable;
