import ButtonComponent from 'components/ButtonComponent/ButtonComponent';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Document } from 'lib/types';
import { Editor as DraftEditor } from 'react-draft-wysiwyg';
import ImageDraft from 'components/editor/ImageDraft';
import ImageLayout from 'components/editor/ImageLayout';
import { uploadFile } from 'lib/communication/additionalTab';
import LinkLayout from 'components/editor/LinkLayout';
import draftToHtml from 'draftjs-to-html';
import { removeLinks } from 'lib/communication/news';
import _ from 'lodash';

interface DocumentEditorProps {
    document?: Document;
    onSubmit: (content: string, links: string[]) => void;
    onCancel: () => void;
}

const fontFamilies = [
    { label: 'Arial', style: 'Arial' },
    { label: 'Georgia', style: 'Georgia' },
    { label: 'Times New Roman', style: 'Times New Roman' },
    { label: 'Verdana', style: 'Verdana' },
    { label: 'Sans Serif', style: 'Sans-serif' }
];

export default function DocumentEditor({ document, onSubmit, onCancel }: DocumentEditorProps) {
    const [links, setLinks] = useState(document?.links || []);
    const [image, setImage] = useState({
        entityKey: '',
        contentState: new ContentState()
    });
    const [displayPopup, setDisplayPopup] = useState(false);
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [editorState, setEditorState] = useState(() => {
        const draft = htmlToDraft(
            document?.content ?? '',
            (nodeName, rawNode) => {
                if (nodeName === 'img') {
                    const node = rawNode as HTMLImageElement;
                    return {
                        type: 'IMAGE',
                        mutability: 'IMMUTABLE',
                        data: {
                            width: node.style.width || node.getAttribute('width') || node.width || 'auto',
                            height: node.style.height || node.getAttribute('height') || node.height || 'auto',
                            src: node.getAttribute('src'),
                            href: node.dataset.href
                        }
                    };
                }
            }
        );
        return EditorState.createWithContent(
            ContentState.createFromBlockArray(
                draft.contentBlocks,
                draft.entityMap
            )
        );
    });

    useEffect(() => {
        if (image.entityKey !== '') {
            setWidth(image.contentState.getEntity(image.entityKey).getData().width);
            setHeight(image.contentState.getEntity(image.entityKey).getData().height);
            setDisplayPopup(true);
        }
    }, [image]);

    const linkFunction = ({ title, target, targetOption }: { title: string, target: string, targetOption: { linkTargetOption: string, file?: File } }) => {
        if (targetOption.file !== undefined) {
            const filename = `${Date.now()}_${targetOption.file.name}`;
            const newFile = new File([targetOption.file], `${Date.now()}_${targetOption.file.name}`);
            uploadFile(newFile);
            target = `/storage/${filename}`;
        }
        setLinks((state) => [...state, target]);
        return ({ title, target, targetOption: targetOption.linkTargetOption });
    };

    return (
        <>
            <Modal show={displayPopup} onHide={() => setDisplayPopup(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Zmień rozmiar obrazka</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: 'center' }}>
                        <div className='rdw-image-modal-size'>
                            &#8597;&nbsp;
                            <input
                                onChange={(event) => setHeight(event.target.value)}
                                onBlur={(event) => setHeight(event.target.value)}
                                value={height}
                                name='height'
                                className='rdw-image-modal-size-input'
                                placeholder='Height'
                            />
                            &nbsp;&#8596;&nbsp;
                            <input
                                onChange={(event) => setWidth(event.target.value)}
                                onBlur={(event) => setWidth(event.target.value)}
                                value={width}
                                name='width'
                                className='rdw-image-modal-size-input'
                                placeholder='Width'
                            />
                        </div>
                    </div>
                    <div className='NewOrder-summaryCancelConfirm-buttons'>
                        <ButtonComponent
                            marginLeft='0'
                            marginRight='0'
                            width='45%'
                            text='OK'
                            onClick={() => {
                                image.contentState.mergeEntityData(
                                    image.entityKey,
                                    { width }
                                );
                                image.contentState.mergeEntityData(
                                    image.entityKey,
                                    { height }
                                );
                                EditorState.push(editorState, image.contentState, 'change-block-data');
                                setDisplayPopup(false);
                            }}
                        />
                        <ButtonComponent
                            marginLeft='10%'
                            marginRight='0'
                            width='45%'
                            text='Anuluj'
                            onClick={() => { setDisplayPopup(false); }}
                        />
                    </div>
                </Modal.Body>
            </Modal>
            <Form onSubmit={(evt: React.FormEvent<HTMLFormElement>) => {
                evt.preventDefault();
                const content = draftToHtml(
                    convertToRaw(editorState.getCurrentContent()),
                    undefined,
                    undefined,
                    (args) => {
                        if (args.type !== 'IMAGE') return;
                        const { href, src, alt } = args.data;
                        if (!href) {
                            return `<div><img src="${src}" alt="${alt || ''}" height="${(!args.data.height || Number.isNaN(parseInt(args.data.height))) ? 'auto' : args.data.height}" width="${(!args.data.width || Number.isNaN(parseInt(args.data.width))) ? 'auto' : args.data.width}" /></div>`;
                        }
                        return `<div><a href="${href}" target="_blank"><img src="${src}" data-href="${href}" alt="${alt || ''}" height="${(!args.data.height || Number.isNaN(parseInt(args.data.height))) ? 'auto' : args.data.height}" width="${(!args.data.width || Number.isNaN(parseInt(args.data.width))) ? 'auto' : args.data.width}" /></a></div>`;
                    }
                );
                let newLinks = links;
                _.forEach(links, (link) => {
                    if (!content.includes(link)) {
                        newLinks = newLinks.filter((e) => e !== link);
                        removeLinks(document?.id !== undefined ? document.id : -1, link);
                    }
                });
                onSubmit(content, newLinks);
            }}
            >
                <Form.Group controlId='NewsEditor-content' className='NewsEditor-content'>
                    <div style={{ marginBottom: '0.5rem' }}>
                        <span>Treść</span>
                    </div>
                    <DraftEditor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        customBlockRenderFunc={(contentBlock) => {
                            const type = contentBlock.getType();
                            const component = {
                                component: ImageDraft,
                                editable: false,
                                props: {
                                    setImage,
                                    config: {
                                        onChange: setEditorState,
                                        editorState
                                    },
                                    uploadFile: (file: any) => {
                                        const newFile = new File([file], `${Date.now()}_${file.name}`);
                                        return new Promise((res, reg) => {
                                            uploadFile(newFile).then((e) => {
                                                setLinks((state) => [...state, e]);
                                                res(e);
                                            }, (error) => {
                                                alert(error);
                                                reg(error);
                                            });
                                        });
                                    }
                                }
                            };
                            if (type === 'atomic') {
                                return component;
                            }
                            if (contentBlock.getEntityAt(0)) {
                                const contentState = editorState.getCurrentContent();
                                const entity = contentState.getEntity(contentBlock.getEntityAt(0));
                                if (entity && entity.getType() === 'IMAGE') {
                                    return component;
                                }
                            }
                        }}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'image'],
                            inline: {
                                options: ['bold', 'italic', 'underline']
                            },
                            image: {
                                component: ImageLayout,
                                uploadEnabled: true,
                                previewImage: true,
                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                alt: { present: true, mandatory: false },
                                defaultSize: {
                                    height: 'auto',
                                    width: '600'
                                },
                                uploadCallback: (file: any) => {
                                    const newFile = new File([file], `${Date.now()}_${file.name}`);
                                    return new Promise((res, reg) => {
                                        uploadFile(newFile).then((e) => {
                                            setLinks((state) => [...state, e]);
                                            res(e);
                                        }, (error) => {
                                            alert(error);
                                            reg(error);
                                        });
                                    });
                                }
                            },
                            link: {
                                component: LinkLayout,
                                linkCallback: linkFunction
                            },
                            fontFamily: {
                                options: _.map(fontFamilies, 'style')
                            }
                        }}
                    />
                </Form.Group>
                <ButtonComponent
                    marginLeft='0'
                    width='8em'
                    marginRight='14px'
                    text='Anuluj'
                    onClick={onCancel}
                    onHoverAnimation={3}
                />
                <ButtonComponent
                    marginLeft='0'
                    marginRight='0'
                    width='8em'
                    text='Zapisz'
                    type='submit'
                    onHoverAnimation={3}
                />
            </Form>

        </>
    );
}
