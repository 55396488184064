import './NewOrder.scss';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { LG } from 'lib/util';
import { useMediaQuery } from 'react-responsive';
import { FaAngleLeft } from 'react-icons/fa';
import { dataActions } from 'reducers';
import store from 'store';
import ButtonComponent from 'components/ButtonComponent';

interface CartProps {
    show: boolean;
    close: () => void;
}

const CartReset = (props: CartProps) => {
    const isDesktop = useMediaQuery({ minWidth: LG });

    const [clearCart, setClearCart] = useState(false);
    const changeClearCart = () => {
        if (clearCart) setClearCart(false);
        else setClearCart(true);
    };

    return (
        <>
            <Modal show={clearCart} onHide={changeClearCart} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Uwaga</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: 'center' }}>Czy na pewno chcesz usunąć zawartość koszyka?</div>
                    <div className='NewOrder-summaryCancelConfirm-buttons'>
                        <ButtonComponent
                            marginLeft='0'
                            marginRight='0'
                            width='45%'
                            text='TAK'
                            onClick={() => {
                                store.dispatch(dataActions.clearLocalCart());
                                changeClearCart();
                                props.close();
                            }}
                            onHoverAnimation={3}
                        />
                        <ButtonComponent
                            marginLeft='10%'
                            marginRight='0'
                            width='45%'
                            text='NIE'
                            onClick={changeClearCart}
                            onHoverAnimation={3}
                        />
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={props.show} onHide={props.close} dialogClassName={!isDesktop ? 'NewOrder-dialog' : ''} backdrop='static' centered>
                {isDesktop ? (
                    <>
                        <Modal.Header closeButton className='NewOrder-modal-header'>
                            <Modal.Title>Zawartość koszyka</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ paddingTop: '0.5rem' }} className='NewOrder-modal-body'>
                            <div style={{ textAlign: 'center' }}>
                                W koszyku znajdują się produkty pochodzące z wcześniej przygotowanego zamówienia.
                                <br></br>
                                Czy chcesz wyczyścić zawartość koszyka?
                            </div>
                        </Modal.Body>
                    </>
                ) : (
                    <>
                        <Modal.Header>
                            <FaAngleLeft size='40px' onClick={props.close} />
                            <Modal.Title>Zawartość koszyka</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ display: 'flex', padding: '0', alignItems: 'center' }}>
                            <div style={{ textAlign: 'center' }}>
                                W koszyku znajdują się produkty pochodzące z wcześniej przygotowanego zamówienia.
                                <br></br>
                                Czy chcesz wyczyścić zawartość koszyka?
                            </div>
                        </Modal.Body>
                    </>
                )}
                <Modal.Footer className='NewOrder-modal-footer'>
                    <ButtonComponent
                        text='WYCZYŚĆ'
                        onClick={changeClearCart}
                        marginLeft='3px'
                        marginTop='0px'
                        marginBottom='0px'
                        variant='danger'
                        brandColoredButton={false}
                    />
                    <ButtonComponent
                        text='KONTYNUUJ'
                        onClick={props.close}
                        marginRight='3px'
                        marginTop='0px'
                        marginBottom='0px'
                        brandColoredButton={false}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CartReset;
