import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userService } from 'services';
import { User } from 'lib';
import { useSelector } from 'react-redux';
import { AppState } from './app';

const sessionSlice = createSlice({
    name: 'session',
    initialState: { user: userService.get() as User | null },
    reducers: {
        setUser(state, action: PayloadAction<User | null>) {
            state.user = action.payload;

            userService.set(action.payload);
        },
        clearAfterLogout(state) {
            state.user = null;
            userService.set(null);
        }
    }
});

export default sessionSlice.reducer;

export const sessionActions = sessionSlice.actions;

export const useUser = () => useSelector((state: AppState) => state.session.user);
