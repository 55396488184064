import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { resetPassword } from 'lib/communication/userInfo';
import { Link, useHistory } from 'react-router-dom';

import Spinner from 'components/Spinner/Spinner';
import './Login.scss';
import ButtonComponent from 'components/ButtonComponent';

interface FormElements extends HTMLFormControlsCollection {
    login: HTMLInputElement;
    phone: HTMLInputElement;
}

const ResetPassword = () => {
    const [waiting, setWaiting] = useState(false);
    const history = useHistory();

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWaiting(true);

        const form = event.currentTarget;

        const { login, phone } = form.elements as FormElements;
        try {
            await resetPassword(login.value, phone.value);
            alert(`Reset hasła powiódł się. Na numer telefonu została wysłana wiadomość z informacjami logowania.`);
            history.replace('/');
        } catch (ex) { }
        setWaiting(false);
        form.reset();
    };

    return (
        <>
            <h3>Resetowanie hasła</h3>
            {waiting && <Spinner />}
            <div className='reset-password-info'><h6>Proszę podać login oraz numer telefonu, powiązany ze wskazanym kontem.</h6></div>
            <Form noValidate onSubmit={onSubmit} className='Login-body'>
                <Form.Group controlId='login'>
                    <Form.Label>Login</Form.Label>
                    <Form.Control type='text' required />
                    <Form.Control.Feedback type='invalid'>Podaj login.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='phone'>
                    <Form.Label>Numer telefonu</Form.Label>
                    <Form.Control type='tel' required />
                    <Form.Control.Feedback type='invalid'>Podaj numer telefonu</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='text-center'>
                    <ButtonComponent
                        text='Resetuj hasło'
                        type='submit'
                        width='250px'
                        onHoverAnimation={3}
                    />
                </Form.Group>
            </Form>
            <div className='d-flex flex-wrap align-items-center justify-content-center'>
                <Link to={`${process.env.PUBLIC_URL}/`}>Powrót do logowania</Link>
            </div>
        </>
    );
};

export default ResetPassword;
