import './ButtonComponent.scss';
import React from 'react';
import { Button } from 'react-bootstrap';
import { LG } from 'lib/util';
import { useMediaQuery } from 'react-responsive';
import { ButtonType } from 'react-bootstrap/esm/Button';

export type Visibility = 'hidden' | 'visible';

interface ButtonComponentProps {
    children?: React.ReactNode;
    text?: string;
    onClick?: (event: any) => void;
    minDesktopResolution?: number;
    width?: string;
    height?: string;
    disabled?: boolean;
    backgroundColor?: string;
    marginTop?: string;
    marginRight?: string;
    marginBottom?: string;
    marginLeft?: string;
    margin?: string;
    variant?: string;
    type?: ButtonType;
    whiteSpace?: string;
    padding?: string;
    visibility?: Visibility;
    onBlack?: boolean;
    brandColoredButton?: boolean;
    onHoverAnimation?: number;
}

const ButtonComponent = ({
    children,
    text,
    onClick,
    minDesktopResolution = LG,
    width = 'auto',
    height = '42px',
    disabled = false,
    backgroundColor = '',
    marginTop = '10px',
    marginRight = '10px',
    marginBottom = '10px',
    marginLeft = '10px',
    margin = '',
    variant = 'dark',
    type = 'button',
    whiteSpace = 'normal',
    padding = '0.05rem 0.75rem',
    visibility = 'visible',
    onBlack = false,
    brandColoredButton = true,
    onHoverAnimation = 0
}: ButtonComponentProps) => {
    const isDesktop = useMediaQuery({ minWidth: minDesktopResolution });
    if (margin.length) {
        marginTop = margin;
        marginRight = margin;
        marginBottom = margin;
        marginLeft = margin;
    }

    return (
        <Button
            className={`${isDesktop ? 'ButtonComponent-buttonDesktop' : 'ButtonComponent-buttonMobile'}
            ${onBlack ? 'ButtonComponent-OnBlack' : ''} ${brandColoredButton && onHoverAnimation !== 2 && !onBlack ? 'ButtonComponent-BrandColored' : ''}
                ${onHoverAnimation === 1 ? 'ButtonComponent-onHoverAnimationType1' : ''}
                ${onHoverAnimation === 2 ? 'ButtonComponent-onHoverAnimationType2-yellow-element ButtonComponent-arrow' : ''}
                ${onHoverAnimation === 3 ? 'ButtonComponent-onHoverAnimationType3-yellow-element ButtonComponent-arrow' : ''}
                `}
            disabled={disabled}
            onClick={onClick}
            variant={variant}
            type={type}
            style={{
                width,
                height,
                backgroundColor,
                marginTop,
                marginRight,
                marginBottom,
                marginLeft,
                padding,
                visibility,
                whiteSpace: whiteSpace as React.CSSProperties['whiteSpace']
            }}
        >
            {text}
            {children}
        </Button>
    );
};

export default ButtonComponent;
