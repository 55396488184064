import _ from 'lodash';
import React from 'react';
import { Col, Form, FormLabel } from 'react-bootstrap';

type SelectOptions = { value: number | string, label: string }[];
type RadioOptions = {
    value: number | string;
    label: string;
    id: string;
    defaultChecked: boolean;
}[];

interface AsSelectProps {
    inputType: 'select';
    customOptions: SelectOptions;
    id: string;
    value: string | number;
    changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
    headerText?: string;
    additionalStyles?: React.CSSProperties;
    inTable?: boolean;
}

interface AsRadioProps {
    inputType: 'radio';
    customOptions: RadioOptions;
    id: string;
    headerText?: string;
    additionalStyles?: React.CSSProperties;
}

type SettingsItemProps = AsSelectProps | AsRadioProps;

const SettingsItem = ({ headerText, inputType, id, additionalStyles, customOptions, ...inputTypeRelatedProps }: SettingsItemProps) => {
    if (inputType === 'select') {
        const { changeHandler, inTable, value } = inputTypeRelatedProps as AsSelectProps;
        const options: SelectOptions = customOptions as SelectOptions;

        return inTable ? (
            <>
                {
                    headerText && (
                        <FormLabel column xs='auto' htmlFor={id} className='pr-0' style={{ paddingLeft: '0' }}>
                            {headerText}
                        </FormLabel>
                    )
                }
                <Col xs='auto'>
                    <Form.Control
                        as={inputType}
                        size='sm'
                        id={id}
                        value={value}
                        onChange={changeHandler}
                        style={{ height: '37px', fontSize: '1rem' }}
                    >
                        {
                            _.map(options, (option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))
                        }

                    </Form.Control>
                </Col>
            </>
        ) : (
            <div style={additionalStyles}>
                {
                    headerText && (
                        <FormLabel htmlFor={id}>
                            {headerText}
                        </FormLabel>
                    )
                }
                <Form.Control
                    as={inputType}
                    size='sm'
                    id={id}
                    value={value}
                    onChange={changeHandler}
                    style={{ height: '37px', fontSize: '1rem' }}
                >
                    {
                        _.map(options, (option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))
                    }

                </Form.Control>
            </div>
        );
    } if (inputType === 'radio') {
        const options: RadioOptions = customOptions as RadioOptions;

        return (
            <Form.Group controlId={id} style={additionalStyles}>
                {
                    headerText && (
                        <Form.Label>
                            {headerText}
                        </Form.Label>
                    )
                }
                {
                    _.map(options, (option) => (
                        <Form.Check
                            key={option.value}
                            type='radio'
                            label={option.label}
                            value={option.value}
                            name={id}
                            id={option.id}
                            defaultChecked={option.defaultChecked}
                        />
                    ))
                }
            </Form.Group>

        );
    }

    return null;
};

export default SettingsItem;
