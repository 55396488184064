import SettingsItem from 'components/SettingsItem';
import React from 'react';

interface AsSelectProps {
    isPlateCreatorOn: boolean;
    initialValue: string;
    changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isDesktop: boolean;
    isForMobileView?: boolean;
}

interface AsRadioProps {
    isPlateCreatorOn: boolean;
    isDesktop: boolean;
    headerText: string;
    initialValue: string;
    isForMobileView?: boolean;
}

type DefaultOrdersViewSettingWrapperProps = AsSelectProps | AsRadioProps;

const DefaultOrdersViewSettingWrapper = (props: DefaultOrdersViewSettingWrapperProps) => {
    const { isPlateCreatorOn, isDesktop, isForMobileView, initialValue } = props;
    if ('changeHandler' in props) {
        // return as select when changeHandler provided
        const { changeHandler } = props;
        const options = [
            { value: 'basic', label: 'Wyszukiwanie proste' },
            { value: 'advanced', label: 'Wyszukiwanie zaawansowane' },
            ...(!isForMobileView ? [{ value: 'gutters', label: 'Orynnowanie' }] : []),
            ...(!isForMobileView && isPlateCreatorOn ? [{ value: 'plate', label: 'Kreator płyt warstwowych' }] : [])
        ];
        return (
            <SettingsItem
                inputType='select'
                customOptions={options}
                id={isForMobileView ? 'mobileView' : 'desktopView'}
                value={initialValue}
                changeHandler={changeHandler}
                additionalStyles={{ width: isDesktop ? '300px' : '220px', height: '37px' }}
            />
        );
    }

    // return as radio
    const { headerText } = props;
    const options = [
        { value: 'basic', label: 'wyszukiwanie proste', id: `radio-${isForMobileView ? 'mobile' : 'desktop'}-basic`, defaultChecked: initialValue === 'basic' },
        { value: 'advanced', label: 'wyszukiwanie zaawansowane', id: `radio-${isForMobileView ? 'mobile' : 'desktop'}-advanced`, defaultChecked: initialValue === 'advanced' },
        ...(!isForMobileView ? [{ value: 'gutters', label: 'Orynnowanie', id: 'radio-desktop-gutters', defaultChecked: initialValue === 'gutters' }] : []),
        ...(!isForMobileView && isPlateCreatorOn ? [{ value: 'plate', label: 'kreator płyt warstwowych', id: 'radio-desktop-plate', defaultChecked: initialValue === 'plate' }] : [])
    ];
    return (
        <SettingsItem
            inputType='radio'
            headerText={headerText}
            customOptions={options}
            id={`newDefaultAssortmentView${!isForMobileView ? 'Desktop' : 'Mobile'}`}
        />
    );
};

export default DefaultOrdersViewSettingWrapper;
