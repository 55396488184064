import React, { useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { News as NewsRecord } from 'lib';
import './Editor.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { DATE_ERROR_END, DATE_ERROR_START, LG } from 'lib/util';
import DocumentEditor from 'components/DocumentEditor/DocumentEditor';
import { CreateNews } from 'lib/types';
import DatePicker from 'components/DatePicker/DatePicker';
import { useMediaQuery } from 'react-responsive';

interface NewsEditorProps {
    news?: NewsRecord;
    onSubmit: (value: NewsRecord | CreateNews) => void;
    onCancel: () => void;
}

const NewsEditor = ({ news, onSubmit, onCancel }: NewsEditorProps) => {
    const isDesktop = useMediaQuery({ minWidth: LG });

    const [newsTitle, setNewsTitle] = useState(news?.title || '');
    const [titleIsInvalid, setTitleIsInvalid] = useState(false);
    const [dateStart, setDateStart] = useState(news?.dateStart || new Date().toISOString().split('T')[0]);
    const [dateEnd, setDateEnd] = useState(news?.dateEnd || '');
    const [dateErrorMessage, setDateErrorMessage] = useState('');
    const [dateStartIsInvalid, setDateStartIsInvalid] = useState(false);
    const [dateEndIsInvalid, setDateEndIsInvalid] = useState(false);

    const dateValidate = (dateStartIsInvalidValue: React.SetStateAction<boolean>, dateEndIsInvalidValue: React.SetStateAction<boolean>) => {
        setDateStartIsInvalid(dateStartIsInvalidValue);
        setDateEndIsInvalid(dateEndIsInvalidValue);
    };

    const inputCheck = (): boolean => {
        let ok = false;
        if (!newsTitle || newsTitle.trim() === '') {
            setTitleIsInvalid(true);
            return false;
        }
        setTitleIsInvalid(false);
        if (!dateStart || dateStart === '') {
            setDateErrorMessage(DATE_ERROR_START);
            setDateStartIsInvalid(true);
        } else if (dateEnd && dateEnd !== '' && dateStart >= dateEnd) {
            setDateErrorMessage(DATE_ERROR_END);
            setDateEndIsInvalid(true);
        } else {
            ok = true;
        }

        if (ok) {
            setDateErrorMessage('');
            dateValidate(false, false);
            return true;
        }
        return false;
    };

    const handleSubmit = (content: string, links: string[]) => {
        if (!inputCheck()) return;
        if (news !== undefined) {
            onSubmit({
                title: newsTitle,
                dateStart,
                dateEnd,
                document: {
                    content,
                    links
                },
                time: news?.time,
                isArchived: news?.isArchived ?? false,
                id: news?.id,
                pinned: news?.pinned ?? false
            });
        } else {
            onSubmit({
                title: newsTitle,
                dateStart,
                dateEnd,
                document: {
                    content,
                    links
                }
            });
        }
    };

    return (
        <>
            <Form>
                <Form.Group controlId='NewsEditor-title'>
                    <Form.Label>Tytuł</Form.Label>
                    <Form.Control
                        type='text'
                        value={newsTitle}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            const val = evt.target.value;
                            setNewsTitle(val);
                        }}
                        isInvalid={titleIsInvalid}
                    />
                    <Form.Control.Feedback type='invalid'> Tytuł nie może być pusty. </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId='NewsEditor-date' className='NewsEditor-date'>
                    <Form.Row className='NewsEditor-dateRow' style={isDesktop ? { display: 'flex' } : { display: 'block' }}>
                        <Form.Group as={Col}>
                            <Form.Label htmlFor='from'>Data publikacji</Form.Label>
                            <DatePicker type='date' id='from' onChange={(event) => setDateStart(event.target.value)} value={dateStart} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label htmlFor='to'>Data zakończenia wyświetlania</Form.Label>
                            <DatePicker type='date' id='to' onChange={(event) => setDateEnd(event.target.value)} value={dateEnd} />
                        </Form.Group>
                    </Form.Row>
                    {(dateStartIsInvalid || dateEndIsInvalid) && (
                        <span className='dateError-message'>
                            {' '}
                            {dateErrorMessage}
                            {' '}
                        </span>
                    )}
                </Form.Group>
            </Form>
            <DocumentEditor document={news?.document} onCancel={onCancel} onSubmit={(content, links) => handleSubmit(content, links)} />
        </>
    );
};

export default NewsEditor;
