import React, { useEffect, useState } from 'react';
import { AppState } from 'reducers';
import { useSelector } from 'react-redux';
import Spinner from 'components/Spinner';
import { getSchemaValidationResults, getTestsResults } from 'lib/communication/webserviceTests';
import './TestsResults.scss';
import _, { size } from 'lodash';
import { Collapse } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { LG } from 'lib/util';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { NUMBER_OF_TESTS_RESULTS_ON_DESKTOP, NUMBER_OF_TESTS_RESULTS_ON_MOBILE } from 'lib/types';
import AutomaticTestsResults from './AutomaticTestsResults/AutomaticTestsResults';
import SchemaValidationResults from './SchemaValidationResults/SchemaValidationResults';

const TestsResults = () => {
    const isDesktop = useMediaQuery({ minWidth: LG });
    const errorsOccured = useSelector((state: AppState) => state.data.schemaValidationResults.errorsOccured);
    const testResults = useSelector((state: AppState) => state.data.webserviceTestResults.results);
    const webserwisTestsResultsIsFetching = useSelector((state: AppState) => state.data.webserviceTestResults.props.isFetching);
    const schemaValidationResultsIsFetching = useSelector((state: AppState) => state.data.schemaValidationResults.props.isFetching);

    const [automaticTestsOpen, setAutomaticTestsOpen] = useState(false);
    const [schemaValidationResultsOpen, setSchemaValidationResultsOpen] = useState(false);

    useEffect(() => {
        getTestsResults();
    }, []);

    useEffect(() => {
        getSchemaValidationResults(isDesktop ? NUMBER_OF_TESTS_RESULTS_ON_DESKTOP : NUMBER_OF_TESTS_RESULTS_ON_MOBILE);
    }, [isDesktop]);

    if (webserwisTestsResultsIsFetching && schemaValidationResultsIsFetching) return <Spinner />;
    return (
        <>
            <div
                className={`TestsResult-CollapseHeader d-flex align-items-center`}
                onClick={() => setAutomaticTestsOpen(!automaticTestsOpen)}
            >
                <b className={isDesktop ? `desktop-font-size` : `mobile-font-size`}>Automatyczne testy</b>
                <b className={isDesktop ? `desktop-font-size` : `mobile-font-size`}>Data wykonania ostatnich testów: {testResults[0] ? testResults[0].date : 'brak'} Wyniki ostatnich testów: {!testResults[0] ? 'brak' : size(_.filter(testResults[0]?.testCasesResults, ({ status }) => status !== '0')) === 0 ? 'pomyślny' : 'wystąpiły błędy'}</b>
                <b>{automaticTestsOpen ? <FaAngleUp size='40px' /> : <FaAngleDown size='40px' />}</b>
            </div>
            <Collapse in={automaticTestsOpen}>
                <div id='collapse-result'>
                    {automaticTestsOpen && <AutomaticTestsResults />}
                </div>
            </Collapse>
            <br />
            <div
                className={`TestsResult-CollapseHeader d-flex align-items-center`}
                onClick={() => setSchemaValidationResultsOpen(!schemaValidationResultsOpen)}
            >
                <b className={isDesktop ? `desktop-font-size` : `mobile-font-size`}>Walidacja schematów odpowiedzi</b>
                {<b className={isDesktop ? `desktop-font-size` : `mobile-font-size`}>Wyniki ostatnich testów: {errorsOccured ? 'wystąpiły błędy' : 'brak błędów'}</b>}
                <b>{schemaValidationResultsOpen ? <FaAngleUp size='40px' /> : <FaAngleDown size='40px' />}</b>
            </div>
            <Collapse in={schemaValidationResultsOpen}>
                <div id='collapse-result'>
                    {schemaValidationResultsOpen && <SchemaValidationResults />}
                </div>
            </Collapse>
        </>
    );
};

export default TestsResults;
