import React, { useEffect, useState } from 'react';
import ButtonComponent from 'components/ButtonComponent';
import { AppState, useUser } from 'reducers';
import ReactMarkdown from 'react-markdown';
import DocumentEditor from 'components/DocumentEditor/DocumentEditor';
import { useSelector } from 'react-redux';
import { getContentFromDocument } from 'lib/util';
import { getTermsAndConditions, setTermsAndConditions } from 'lib/communication/promotionalProgram';
import Spinner from 'components/Spinner/Spinner';

const PromotionRegulations = () => {
    const promotionProgramPolicy = useSelector((state: AppState) => state.data.promotionalProgram.promotionProgramPolicy);
    const [editModeOn, setEditModeOn] = useState<boolean>(false);
    const [content, setContent] = useState<string>('');
    const [error, setError] = useState(false);

    const user = useUser();

    useEffect(() => {
        getTermsAndConditions('promotion_program_policy')
            .then((result) => setError(!result));
    }, []);

    useEffect(() => {
        if (promotionProgramPolicy.document !== undefined) {
            setContent(promotionProgramPolicy.document !== undefined ? getContentFromDocument(promotionProgramPolicy.document) : '');
        }
    }, [promotionProgramPolicy]);

    const handleUpdate = (contentData: string, links: string[]) => {
        setTermsAndConditions(contentData, 'promotion_program_policy', links);
        setEditModeOn(false);
    };

    const onCancel = () => {
        setEditModeOn(false);
    };

    if (error || promotionProgramPolicy === undefined) {
        return <Spinner showError={error} />;
    }

    return (
        <div className='mx-3'>
            <div className='text-right mb-2'>
                {
                    (!editModeOn && user?.permissions.canEditTerms) && <ButtonComponent onHoverAnimation={3} onClick={() => { setEditModeOn(true); }}>Edytuj</ButtonComponent>
                }
            </div>
            <div>
                {
                    !editModeOn ?
                        <div style={{ textAlign: 'left', width: '100%' }}>
                            <ReactMarkdown escapeHtml={false} source={content} />
                        </div> : user?.permissions.canEditTerms && <DocumentEditor document={promotionProgramPolicy.document} onCancel={onCancel} onSubmit={(contentData, links) => handleUpdate(contentData, links)} />
                }
            </div>
        </div>
    );
};

export default PromotionRegulations;
