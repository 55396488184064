import React, { useEffect, useState } from 'react';
import './AdditionalTab.scss';
import ReactMarkdown from 'react-markdown';
import { getAdditionalTabDetails, updateAdditionalTabDetails } from 'lib/communication/additionalTab';
import { FaEdit } from 'react-icons/fa';
import { Button, Form } from 'react-bootstrap';
import { AppState, useUser } from 'reducers';
import { useSelector } from 'react-redux';
import { AdditionalTab as AdditionalTabDetails } from 'lib/types';
import DocumentEditor from 'components/DocumentEditor/DocumentEditor';
import { getContentFromDocument } from 'lib/util';

export default function AdditionalTab() {
    const user = useUser();
    const additionalTabInfo = useSelector((state: AppState) => state.data.additionalTab.details);

    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [tabName, setTabName] = useState<string>('');
    const [content, setContent] = useState<string>('');

    const [editModeOn, setEditModeOn] = useState<boolean>(false);

    useEffect(() => {
        getAdditionalTabDetails();
    }, []);

    useEffect(() => {
        setIsVisible(additionalTabInfo.isVisible);
        setTabName(additionalTabInfo.name);
        setContent(additionalTabInfo.document !== undefined ? getContentFromDocument(additionalTabInfo.document) : '');
    }, [additionalTabInfo]);

    const handleUpdate = (isVisibleFromCheckBox?: boolean, contentFromEditor?: string, links?: string[]) => {
        const updatedAdditionalTabDetails: AdditionalTabDetails = {
            isVisible: isVisibleFromCheckBox !== undefined ? isVisibleFromCheckBox : isVisible,
            document: {
                content: contentFromEditor !== undefined ? contentFromEditor : content,
                links: links !== undefined ? links : []
            },
            name: tabName
        };
        updateAdditionalTabDetails(updatedAdditionalTabDetails);
    };

    const onCancelEdit = () => {
        setEditModeOn(false);
    };

    const onSubmitContent = (contentData: string, links: string[]) => {
        handleUpdate(isVisible, contentData, links);
        setEditModeOn(false);
    };

    if (editModeOn && additionalTabInfo && user?.permissions.canEditAdditionalTab) {
        return (
            <>
                <Form.Group controlId='AdditionalTab-name'>
                    <Form.Label>Nazwa zakładki</Form.Label>
                    <Form.Control
                        type='text'
                        value={tabName}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                            setTabName(evt.target.value);
                        }}
                    />
                </Form.Group>
                <DocumentEditor document={additionalTabInfo.document} onCancel={onCancelEdit} onSubmit={(contentData, links) => onSubmitContent(contentData, links)} />
            </>
        );
    }

    return (
        <>
            <div className='AdditionalTab-container'>
                {user?.permissions.canEditAdditionalTab &&
                    <div className='AdditionalTab-header-container'>
                        <Form>
                            <Form.Check
                                className='AdditionalTab-Switch'
                                type='switch'
                                id='switch-enabled'
                                label='Widoczność zakładki'
                                onChange={() => { handleUpdate(!isVisible); setIsVisible(!isVisible); }}
                                checked={isVisible}
                            />
                        </Form>
                        <Button
                            className='AdditionalTab-Button'
                            title='Edytuj'
                            variant='link'
                            onClick={() => { setEditModeOn(true); }}
                        >
                            <FaEdit />
                        </Button>
                    </div>}
                <ReactMarkdown source={content} escapeHtml={false} />
            </div>
        </>
    );
}
