import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Spinner as Spin } from 'react-bootstrap';
import './PromotionProducts.scss';
import { AppState } from 'reducers';
import { useSelector } from 'react-redux';
import { DeliveryOption, Address, PromotionalProduct } from 'lib/types';
import {
    getPromotionalProducts,
    getPromotionalPoints,
    addPromotionalOrder
} from 'lib/communication/promotionalProgram';

import PromotionProductsList from './PromotionProductsList';
import PromotionProductsSummary from './PromotionProductsSummary';
import ButtonComponent from 'components/ButtonComponent';

const PromotionProducts = () => {
    const products = useSelector((state: AppState) => state.data.promotionalProgram.products);
    const points = useSelector((state: AppState) => state.data.promotionalProgram.points);
    const pointsSpent = useSelector((state: AppState) => state.data.promotionalProgram.pointsSpent);
    const delivery = useSelector((state: AppState) => state.data.promotionalProgram.delivery);
    const promotion = useSelector((state: AppState) => state.conf.promotion);
    const isFetchingPoints = useSelector((state: AppState) => state.data.promotionalProgram.props.isFetchingPoints);
    const isFetchingProducts = useSelector((state: AppState) => state.data.promotionalProgram.props.isFetchingProducts);
    const [deliveryOption, setDeliveryOption] = useState(null as DeliveryOption | null);
    const [showSummary, setShowSummary] = useState(false);
    const [pointsFetchError, setPointsFetchError] = useState(false);
    const [productsFetchError, setProductsFetchError] = useState(false);
    const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [warningShown, setWarningShown] = useState(false);

    useEffect(() => {
        if (promotion !== '') {
            getPromotionalPoints(promotion)
                .then((result) => setPointsFetchError(!result));

            if (products.length === 0 && !isFetchingProducts) {
                getPromotionalProducts(promotion)
                    .then((result) => setProductsFetchError(!result));
            }
        }
    }, [promotion, products]);

    const [selectedCount, setSelectedCount] = useState<Map<PromotionalProduct, number[]>>(new Map());

    useEffect(() => {
        const map = new Map<PromotionalProduct, number[]>();
        products.forEach((product) => {
            map.set(product, product.options?.map((_) => 0) ?? [0]);
        });
        setSelectedCount(map);
    }, [products]);

    const availablePoints = points;
    const spentPoints = pointsSpent;
    let grandTotalPoints = Array.from(selectedCount.entries())
        .reduce((previous, entity) => previous + entity[1].reduce((prev, curr) => prev + curr) * entity[0].points, 0);
    if (deliveryOption === 'inputAddress') grandTotalPoints += delivery.points;
    const remainingPoints = availablePoints - grandTotalPoints;

    useEffect(() => {
        if (remainingPoints < 0) {
            setShowWarning(true);
        }
    }, [remainingPoints]);

    useEffect(() => {
        if (remainingPoints >= 0) {
            setWarningShown(false);
        }
    }, [remainingPoints]);

    useEffect(() => {
        if (remainingPoints >= 0) {
            setShowWarning(false);
        }
    }, [remainingPoints]);

    const submitNewOrder = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget;

        let address: Address | null;
        switch (deliveryOption) {
            case 'inputAddress':
                address = {
                    firstName: form.firstName.value,
                    lastName: form.lastName.value,
                    companyName: form.companyName.value,
                    address: form.address.value,
                    postalCode: form.postalCode.value,
                    city: form.city.value
                } as Address;
                break;
            case 'distributorAddress':
                address = {
                    firstName: '',
                    lastName: '',
                    companyName: '',
                    address: '',
                    postalCode: '',
                    city: ''
                } as Address;
                break;
            case 'selfPickup':
                address = {
                    firstName: '',
                    lastName: '',
                    companyName: '',
                    address: form.branch.value,
                    postalCode: '',
                    city: ''
                } as Address;
                break;
            default:
                throw new Error('Unknown delivery method');
        }
        const productsInOrder = Array.from(selectedCount.entries())
            .flatMap(([product, counts]) => Array.from(counts.entries())
                .filter(([_, value]) => value !== 0)
                .map(([index, value]) => ({ quantity: value, symKar: product.symKar, option: product.options[index] })));
        if (deliveryOption === 'inputAddress') {
            productsInOrder.push({ quantity: 1, symKar: delivery.symKar, option: '' });
        }

        const sendOrder = async () => {
            const orderAdded = await addPromotionalOrder(
                productsInOrder,
                deliveryOption!,
                address!
            );

            if (orderAdded) {
                getPromotionalPoints(promotion)
                    .then((result) => setPointsFetchError(!result));
            }

            const map = new Map<PromotionalProduct, number[]>();
            products.forEach((product) => {
                map.set(product, product.options?.map((_) => 0) ?? [0]);
            });

            setSelectedCount(map);
            setShowSummary(false);
            setDeliveryOption(null);
            setShowOrderConfirmation(orderAdded);
        };
        sendOrder();
    };

    return (
        <>
            <Table size='sm' borderless className='tr1 tr2 tr3'>
                <thead>
                    <tr>
                        <th>Posiadane punkty</th>
                        <th>Wydane punkty</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (isFetchingPoints || pointsFetchError) ? (
                            <tr>
                                <td>
                                    <Spin animation='border' id='spin' className='PromotionProducts-points-spiner' size='sm' />
                                </td>
                                <td>
                                    <Spin animation='border' id='spin' className='PromotionProducts-points-spiner' size='sm' />
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td>{availablePoints}</td>
                                <td>{spentPoints}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            {
                showSummary ? (
                    <PromotionProductsSummary
                        grandTotalPoints={grandTotalPoints}
                        remainingPoints={remainingPoints}
                        selectedCount={selectedCount}
                        deliveryOption={deliveryOption}
                        setDeliveryOption={setDeliveryOption}
                        setShowSummary={setShowSummary}
                        submitNewOrder={submitNewOrder}
                    />
                ) : (
                    <PromotionProductsList
                        error={productsFetchError}
                        selectedCount={selectedCount}
                        remainingPoints={remainingPoints}
                        setSelectedCount={setSelectedCount}
                        setShowSummary={setShowSummary}
                    />
                )
            }
            <Modal show={showOrderConfirmation} onHide={() => setShowOrderConfirmation(false)}>
                <Modal.Body>Zamówienie zostało poprawnie złożone</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setShowOrderConfirmation(false)}>
                        Zamknij
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={!!(showWarning && !warningShown)} onHide={() => setWarningShown(true)}>
                <Modal.Header className='PromotionProducts-modal-header' />
                <Modal.Body>Niewystarczająca ilość punktów</Modal.Body>
                <Modal.Footer className='PromotionProducts-modal-footer'>
                    <ButtonComponent onHoverAnimation={3} text='Zamknij' onClick={() => setWarningShown(true)} />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PromotionProducts;
