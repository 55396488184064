import { buildResourceLink } from 'lib/communication';
import React, { useState } from 'react';
import { useUser } from 'reducers';
import './Cards.scss';
import { FaEdit } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import EditModalCard from './EditImageModalCard';

interface GroupCardProps {
    click: () => void;
    groupName: string;
    imgSrc: string | null;
    imgAlt: string;
    path: string;
}

const GroupCard = (props: GroupCardProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const user = useUser();

    return (
        <div className='Cards-category' onClick={() => { if (!isModalOpen) props.click(); }}>
            {user?.permissions.canManageAssortmentImages && <EditModalCard path={props.path} setModalVisibility={(visibility: boolean) => { setIsModalOpen(visibility); }} isModalOpen={isModalOpen} name={props.groupName} />}
            <h3>{props.groupName}</h3>
            <img
                src={props.imgSrc ? buildResourceLink(props.imgSrc) : `${process.env.PUBLIC_URL}/images/ikona.jpg`}
                alt={props.imgAlt}
            />
            {user?.permissions.canManageAssortmentImages && (
                <div className='custom-row'>
                    <p className='file-name'>
                        Nazwa pliku:
                        {' '}
                        {props.path.replace('KWWW\\', '').replaceAll(/\\/g, '_')}
                    </p>
                    <div className='edit-button'>
                        <Button
                            variant='outline'
                            title='Edytuj'
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsModalOpen(true);
                            }}
                        >
                            <FaEdit />
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GroupCard;
