import { CreateNews, News } from 'lib/types';
import store from 'store';
import { dataActions } from 'reducers';
import { post, API_ADDRESS, put, get } from 'lib/communication';
import { NewsResponse, Response } from './communicationTypes';
import { PAGE_SIZE } from 'containers/News/News';
import { getCurrentTimeStamp } from 'lib/util';

const setNewsFetching = (isFetching: boolean) => {
    store.dispatch(dataActions.setNewsFetching(isFetching));
};

const setAreNewsGraphicsDownloaded = (isFetching: boolean) => {
    store.dispatch(dataActions.setIsNewsGraphicsFetching(isFetching));
};

const setIsNewsDumpDownloaded = (isFetching: boolean) => {
    store.dispatch(dataActions.setIsNewsDumpFetchng(isFetching));
};

export const getNews = async (
    company: number,
    page: number,
    visible: Boolean
): Promise<boolean> => {
    const route: string = 'news';
    setNewsFetching(true);
    try {
        const response = await get<NewsResponse>(`${API_ADDRESS}${route}?company=${company}&page=${page}&count=${PAGE_SIZE}&archieved=${visible ? 0 : 1}`);
        const data = response.parsedBody;
        if (data) {
            store.dispatch(dataActions.setNewsData(data));
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setNewsFetching(false);
    }
};

export const removeLinks = async (
    id: number,
    value: string
): Promise<boolean> => {
    const route: string = 'news/removeLinks';
    setNewsFetching(true);
    try {
        const response = await post<Response>(`${API_ADDRESS}${route}`, {
            id,
            value
        });
        if (response.ok && response.parsedBody) {
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setNewsFetching(false);
    }
};

export const addNews = async (
    news: CreateNews,
    companyId: number
): Promise<boolean> => {
    const route: string = 'news';
    setNewsFetching(true);
    try {
        const response = await post<Response>(`${API_ADDRESS}${route}`, {
            title: news.title,
            dateStart: news.dateStart,
            dateEnd: news.dateEnd,
            content: news.document.content,
            company: companyId,
            links: news.document.links
        });
        if (response.ok && response.parsedBody) {
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setNewsFetching(false);
    }
};

export const modifyNews = async (
    modifiedNews: News,
    companyId: number
): Promise<boolean> => {
    const route: string = `news/${modifiedNews.id}/`;
    setNewsFetching(true);
    try {
        const response = await put<Response>(
            `${API_ADDRESS}${route}`,
            {
                company: companyId,
                title: modifiedNews.title,
                dateStart: modifiedNews.dateStart,
                dateEnd: modifiedNews.dateEnd,
                content: modifiedNews.document.content,
                time: modifiedNews.time,
                links: modifiedNews.document.links
            }
        );
        store.dispatch(dataActions.updateNews(modifiedNews));
        if (response.ok) {
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setNewsFetching(false);
    }
};

export const archiveNews = async (
    id: number
): Promise<boolean> => {
    const route: string = `news/${id}/archive`;
    try {
        const data = await put<Response>(
            `${API_ADDRESS}${route}`,
            { id }
        );
        if (data.ok) {
            store.dispatch(dataActions.setIsNewsArchived({ id, value: true }));
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setNewsFetching(false);
    }
};

export const unarchiveNews = async (
    id: number
): Promise<boolean> => {
    const route: string = `news/${id}/unarchive`;
    try {
        const data = await put<Response>(
            `${API_ADDRESS}${route}`,
            { id }
        );
        if (data.ok) {
            store.dispatch(dataActions.setIsNewsArchived({ id, value: false }));
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setNewsFetching(false);
    }
};

export const pinNews = async (
    id: number
): Promise<boolean> => {
    const route: string = `news/${id}/pin`;
    try {
        const data = await put<Response>(
            `${API_ADDRESS}${route}`,
            { id }
        );
        if (data.ok) {
            store.dispatch(dataActions.setIsNewsPinned({ id, value: true }));
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error', err);
        return false;
    } finally {
        setNewsFetching(false);
    }
};

export const unpinNews = async (
    id: number
): Promise<boolean> => {
    const route: string = `news/${id}/unpin`;
    try {
        const data = await put<Response>(
            `${API_ADDRESS}${route}`,
            { id }
        );
        if (data.ok) {
            store.dispatch(dataActions.setIsNewsPinned({ id, value: false }));
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error', err);
        return false;
    } finally {
        setNewsFetching(false);
    }
};

export const deleteNews = async (
    id: number
): Promise<boolean> => {
    const route: string = `news/${id}/delete`;
    try {
        const data = await put<Response>(
            `${API_ADDRESS}${route}`,
            { id }
        );
        if (data.ok) {
            return true;
        }
        return false;
    } catch (err) {
        console.log('Error ', err);
        return false;
    } finally {
        setNewsFetching(false);
    }
};

const handleZipDownload = async (route: string, filename: string, setDownload: (val: boolean) => void) : Promise<boolean> => {
    try {
        setDownload(true);
        const response = await get(`${API_ADDRESS}${route}`);

        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/zip' }));
            const aTag = document.createElement('a');
            document.body.append(aTag);
            aTag.href = url;
            aTag.download = `${getCurrentTimeStamp()}_${filename}.zip`;
            aTag.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(aTag);
            return true;
        }

        return false;
    } catch (e) {
        return false;
    } finally {
        setDownload(false);
    }
};

export const downloadNewsGraphics = async () : Promise<boolean> => {
    const route = 'news/downloadGraphics';
    return handleZipDownload(route, 'news_graphics', setAreNewsGraphicsDownloaded);
};

export const downloadNewsDump = async () : Promise<boolean> => {
    const route = 'news/downloadDump';
    return handleZipDownload(route, 'news_dump', setIsNewsDumpDownloaded);
};
