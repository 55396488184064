import SettingsItem from 'components/SettingsItem';
import _ from 'lodash';
import React from 'react';

const options = [
    10, 20, 50, 100
];

interface NumberOfOrdersPerPageSettingWrapperProps {
    initialValue: number;
    changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isDesktop: boolean;
    headerText?: string;
    id: string;
    inTable?: boolean;
}

const NumberOfOrdersPerPageSettingWrapper = ({ initialValue, changeHandler, isDesktop, headerText, id, inTable }: NumberOfOrdersPerPageSettingWrapperProps) => (
    <SettingsItem
        inputType='select'
        customOptions={_.map(options, (option) => ({ value: option, label: option.toString() }))}
        id={id}
        value={initialValue}
        changeHandler={changeHandler}
        additionalStyles={{ width: isDesktop ? '300px' : '220px' }}
        headerText={headerText}
        inTable={inTable}
    />
);

export default NumberOfOrdersPerPageSettingWrapper;
