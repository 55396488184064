import React from 'react';
import Select, { ActionMeta, ValueType } from 'react-select';
import '../../vars.scss';
import { OptionType } from 'lib/types';

interface DropdownProps<T> {
    className?: string;
    placeholder?: string;
    isClearable?: boolean | undefined;
    isSearchable: boolean | undefined;
    isDisabled?: boolean | undefined;
    options: OptionType<T>[];
    value: ValueType<OptionType<T>>;
    onChange: (newValue: ValueType<OptionType<T>>, actionMeta: ActionMeta<OptionType<T>>) => void;
    name?: string;
    filterOption?: (option: OptionType<string>, rawInput: string) => boolean;
}

const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        borderColor: 'dark',
        borderRadius: '0px',
        boxShadow: state.isFocused ? '0 0 0 0.2rem var(--brand-bright)' : 'none'
    }),
    menu: (provided: any) => ({
        ...provided,
        backgroundColor: 'white',
        borderRadius: '0px'
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000' : (state.isFocused ? 'var(--brand-light)' : 'white'),
        color: state.isSelected ? 'white' : 'black'
    })
};

const Dropdown = <T,>({ className, placeholder, isClearable, isSearchable, isDisabled, options, value, onChange, name, filterOption }: DropdownProps<T>) => (
    <Select
        className={className || ''}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isDisabled={isDisabled || false}
        options={options}
        value={value}
        onChange={onChange}
        name={name}
        styles={customStyles}
        filterOption={filterOption}
    />
);

export default Dropdown;
