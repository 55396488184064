import './ScrollUpButton.scss';
import React, { useState, useEffect } from 'react';
import { TiArrowUpThick } from 'react-icons/ti';
import { LG } from 'lib/util';
import { Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

interface ScrollUpButtonProps {
    minDesktopResolution?: number;
}

const ScrollUpButton = ({
    minDesktopResolution = LG
}: ScrollUpButtonProps) => {
    const isDesktop = useMediaQuery({ minWidth: minDesktopResolution });
    const [showScrollUpButton, setShowScrollUpButton] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 300) {
                setShowScrollUpButton(true);
            } else {
                setShowScrollUpButton(false);
            }
        });
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div style={{ position: 'sticky', bottom: '0', marginTop: '15px', pointerEvents: 'none' }}>
            <div className='ScrollUpButton'>
                {
                    showScrollUpButton && (
                        <Button className='ml-auto' onClick={scrollToTop}>
                            {isDesktop ? 'Powróć na górę' : <TiArrowUpThick />}
                        </Button>
                    )
                }
            </div>
        </div>
    );
};

export default ScrollUpButton;
