import { LG } from 'lib/util';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { AppState } from 'reducers';
import _ from 'lodash';
import { NUMBER_OF_TESTS_RESULTS_ON_DESKTOP, NUMBER_OF_TESTS_RESULTS_ON_MOBILE } from 'lib/types';
import PrintStatus from '../PrintStatus';

export default function SchemaValidationResults() {
    const testResults = useSelector((state: AppState) => state.data.schemaValidationResults.results);

    const isDesktop = useMediaQuery({ minWidth: LG });

    return (
        <>
            <div className='TestsResults-container'>
                <div className='TestsResults-body'>
                    <Table bordered striped className={isDesktop ? '' : 'mobile-font-size'}>
                        <thead>
                            <tr>
                                {isDesktop && <th rowSpan={2}>LP.</th>}
                                <th rowSpan={2}>Procedura</th>
                                {
                                    _.times(isDesktop ? NUMBER_OF_TESTS_RESULTS_ON_DESKTOP : NUMBER_OF_TESTS_RESULTS_ON_MOBILE, (index) => (
                                        <th key={`header-${index}`} colSpan={2}>{index + 1}</th>
                                    ))
                                }
                            </tr>
                            <tr>
                                {
                                    <>
                                        {_.times(isDesktop ? NUMBER_OF_TESTS_RESULTS_ON_DESKTOP : NUMBER_OF_TESTS_RESULTS_ON_MOBILE, () => (
                                            <>
                                                <th>Data</th>
                                                <th>Status</th>
                                            </>
                                        ))}
                                    </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(Object.entries(testResults), ([procedureName, testResult], index) => (
                                    <tr style={{ borderTop: '2px solid grey' }} key={procedureName}>
                                        {isDesktop && <td className={'Table-cell'}>{index + 1}</td>}
                                        <td className={'Table-cell'} style={isDesktop ? { overflowWrap: 'break-word' } : { overflowWrap: 'anywhere' }}>
                                            {procedureName}
                                        </td>
                                        {
                                            _.map(_.slice(testResult, 0, isDesktop ? NUMBER_OF_TESTS_RESULTS_ON_DESKTOP : NUMBER_OF_TESTS_RESULTS_ON_MOBILE), (result) => (
                                                <>
                                                    <td className={'Table-cell'}>{result.date}</td>
                                                    <td className={'Table-cell'}>
                                                        <PrintStatus errorType={result.error_type} isError />
                                                    </td>
                                                </>
                                            ))
                                        }
                                        {
                                            _.times((isDesktop ? NUMBER_OF_TESTS_RESULTS_ON_DESKTOP : NUMBER_OF_TESTS_RESULTS_ON_MOBILE) - testResult.length, () => (
                                                <>
                                                    <td className={'Table-cell'}>
                                                        <PrintStatus />
                                                    </td>
                                                    <td className={'Table-cell'}>
                                                        <PrintStatus />
                                                    </td>
                                                </>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </>
    );
}
