import { userService } from 'services';
import { ProductInLocalCart, ProductInOrder } from './types';
import { getTotalPrice } from './utilityMetods';
import _ from 'lodash';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

export const addProductToCart = (product: ProductInLocalCart) => {
    if (window.dataLayer && Array.isArray(window.dataLayer)) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
                currency: userService.get()?.currency,
                value: getTotalPrice(product),
                items: [
                    {
                        item_id: product.product.symKar,
                        item_name: product.product.description,
                        price: product.product.priceInfo,
                        path: product.product.path.join('\\'),
                        quantity: product.quantity
                    }
                ]
            }
        });
    }
};

export const createOrder = (transactionId: string, products: ProductInOrder[], summaryPrice: number) => {
    if (window.dataLayer && Array.isArray(window.dataLayer)) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
                transaction_id: transactionId,
                value: summaryPrice,
                currency: userService.get()?.currency,
                items: _.map(products, (product) => ({
                    item_id: product.product.symKar,
                    item_name: product.product.description,
                    price: product.product.priceInfo,
                    path: product.product.path.join('\\'),
                    quantity: product.quantity
                }))
            }
        });
    }
};
