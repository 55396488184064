import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState, useUser } from 'reducers';
import { getTermsAndConditions, setTermsAndConditions } from 'lib/communication/promotionalProgram';
import Spinner from 'components/Spinner';
import ReactMarkdown from 'react-markdown';
import DocumentEditor from 'components/DocumentEditor/DocumentEditor';
import ButtonComponent from 'components/ButtonComponent';
import { getContentFromDocument } from 'lib/util';

const CookiePolicy = () => {
    const cookiePolicy = useSelector((state: AppState) => state.data.promotionalProgram.cookiePolicy);
    const isFetching = useSelector((state: AppState) => state.data.promotionalProgram.props.isFetching);
    const [error, setError] = useState(false);

    const [content, setContent] = useState<string>('');
    const [editModeOn, setEditModeOn] = useState<boolean>(false);

    const user = useUser();

    const handleUpdate = (contentData: string, links: string[]) => {
        setTermsAndConditions(contentData, 'cookie_policy', links);
        setEditModeOn(false);
    };

    const onCancel = () => {
        setEditModeOn(false);
    };

    useEffect(() => {
        const fetchData = async () => { setError(!(await getTermsAndConditions('cookie_policy'))); };
        fetchData();
    }, []);

    useEffect(() => {
        if (cookiePolicy.document !== undefined) setContent(getContentFromDocument(cookiePolicy.document));
    }, [cookiePolicy]);

    if (isFetching || error) {
        return <Spinner showError={error} />;
    }

    return (
        <div className='mx-3'>
            <div className='text-right mb-2'>
                {
                    (!editModeOn && user?.permissions.canEditTerms) && <ButtonComponent onClick={() => { setEditModeOn(true); }} onHoverAnimation={3}>Edytuj</ButtonComponent>
                }
            </div>
            <div>
                {
                    !editModeOn ?
                        <div style={{ textAlign: 'left', padding: '0 30px' }}>
                            <ReactMarkdown escapeHtml={false} source={content} />
                        </div> : user?.permissions.canEditTerms && <DocumentEditor document={cookiePolicy.document} onCancel={onCancel} onSubmit={(contentData, links) => handleUpdate(contentData, links)} />
                }
            </div>
        </div>
    );
};

export default CookiePolicy;
