import ButtonComponent from 'components/ButtonComponent';
import { downloadAssortmentGraphics, editImages } from 'lib/communication/orders';
import { EditAssortmentImage } from 'lib/types';
import _ from 'lodash';
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';

const AssortmentGraphics = () => {
    const [zipWithImages, setZipWithImages] = useState<File>();
    const [responseMessages, setResponseMessages] = useState<EditAssortmentImage[] | null>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [errorDownloadMessage, setErrorDownloadMessage] = useState<string>();
    const areImagesEditFetching = useSelector((state: AppState) => state.data.orders.editImagesUploadFetching);
    const areImagesDownloadFetching = useSelector((state: AppState) => state.data.orders.isAssortmentGraphicsFetching);

    const setNewZipWithImages = (e: React.ChangeEvent<HTMLInputElement>) => {
        setZipWithImages(undefined);
        if (e.target.files !== null && e.target.files[0] !== undefined) {
            const fileExt = e.target.files[0].name.split('.').pop();
            if (fileExt !== 'zip') {
                setErrorMessage('Nieobsługiwany format pliku');
                setResponseMessages(null);
                setZipWithImages(undefined);
                return;
            }
            const newFile = new File([e.target.files[0]], e.target.files[0].name);
            setZipWithImages(newFile);
        }
        setResponseMessages(null);
        setErrorMessage('');
    };

    const handleNewImagesUpload = async () => {
        setResponseMessages(null);
        if (zipWithImages instanceof File) {
            const response = await editImages(zipWithImages);
            if (typeof response === 'boolean') setResponseMessages(null);
            else setResponseMessages(response);
            setErrorMessage('');
        }
    };

    const handleDownloadAssortmentGraphics = async () => {
        setErrorDownloadMessage('');
        const response = await downloadAssortmentGraphics();
        if (!response) {
            setErrorDownloadMessage('Nie udało się pobrać grafik');
        }
    };

    return (
        <div>
            <h2>Pobranie grafik asortymentu</h2>
            <p>Tutaj możesz pobrać grafiki asortymentu razem z archiwum.</p>
            <p style={{ fontWeight: 'bold', color: 'red' }}>{errorDownloadMessage}</p>
            <br />
            <ButtonComponent
                text='Pobierz grafiki'
                onClick={handleDownloadAssortmentGraphics}
                margin='0.25rem'
                onHoverAnimation={3}
            />
            <div style={{ fontStyle: 'italic', marginRight: 'auto' }}>
                {' '}
                {areImagesDownloadFetching ? <Spinner animation='border' /> : <></>}
                {' '}
            </div>
            <br />
            <h2>Zmiana grafik asortymentu za pomocą paczki</h2>
            <p>Należy umieścić plik o rozszerzeniu .zip bezpośrednio ze zdjęciami, które będą odpowiadały nazwom grup lub produktów.</p>
            <p style={{ fontWeight: 'bold' }}>Maksymalny rozmiar pojedynczej grafiki wynosi 2MB.</p>
            <br />
            <input onChange={setNewZipWithImages} type='file' accept='.zip' />
            <p style={{ fontWeight: 'bold', color: 'red' }}>{errorMessage}</p>
            {responseMessages !== null && responseMessages !== undefined ?
                <div>
                    {_.map(responseMessages, (responseMessage, index) => (
                        <p key={index} style={{ color: responseMessage.error ? 'red' : 'black', fontWeight: responseMessage.error ? 'bold' : 'normal' }}>
                            {responseMessage.message}
                            {' '}
                            [
                            {responseMessage.filename}
                            ]
                        </p>
                    ))}
                </div> :
                <></>}
            <br />
            <ButtonComponent
                text='Zastąp grafiki'
                onClick={handleNewImagesUpload}
                margin='0.25rem'
                onHoverAnimation={3}
            />
            <div style={{ fontStyle: 'italic', marginRight: 'auto' }}>
                {' '}
                {areImagesEditFetching ? <Spinner animation='border' /> : <></>}
                {' '}
            </div>
        </div>
    );
};

export default AssortmentGraphics;
